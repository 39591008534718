@import '../../../../styles/vars.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-white;
  box-shadow: 0px 2px 10px 0px rgba($color-primary-rgb, 0.6);
  transition: all 0.3s ease;
  z-index: 8;
  @media (max-width: $desktop-width) {
    box-shadow: 0px 2px 10px 0px rgba($color-primary-rgb,0.6);
    &.menuOpen {
      box-shadow: none;
      .logoWrapper {
        opacity: 0;
      }
    }
  }
}

.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  @media (max-width: $desktop-width) {
    justify-content: center;
  }
}


.logoWrapper {
  width: 230px;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: $desktop-width) {
    width: 150px;
    transition: opacity 0.3s ease;
  }
}

.menuButton {
  display: none;
  position: absolute;
  top: 50%;
  left: 5vw;
  transform: translateY(-50%);
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  @media (max-width: $desktop-width) {
    display: flex;
  }
}

.menuBar {
  display: block;
  background-color: $color-primary;
  height: 2px;
  width: 30px;
}