@import "../../../styles/vars.scss";

.backdrop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: $color-black;
  opacity: 0.6;
  z-index: 100;
}

.modal {
  position: fixed;
  display: block;
  background-color: $color-white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0 25px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-radius: 5px;
  z-index: 101;
  max-width: 90%;
  &.Center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: $tablet-width) {
      width: 90%;
    }
  }
  &.BottomRight {
    bottom: 10px;
    right: 10px;
  }
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  padding: 0;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 2px;
    height: 15px;
    background-color: $color-grey-dark;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
