@import "../../../styles/vars";

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
  @media (max-width: $desktop-width) {
    padding: 0 5vw;
  }
}
