@import "../../../styles/vars.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  font-family: $font-source-sans-pro;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  transition: transform 0.15s ease-out;
  text-transform: uppercase;
  &:disabled {
    background-color: $color-grey-dark !important;
    border-color: $color-grey-dark !important;
    color: $color-white !important;
    cursor: not-allowed;
  }
  &:hover {
    transform: scale(1.1);
    transition: transform 0.15s ease-out;
  }
  &.primary {
    color: $color-white !important;
    background-color: $color-primary;
    border: 2px solid $color-primary;
  }
  &.secondary {
    color: $color-white !important;
    background-color: $color-secondary;
    border: 2px solid $color-secondary;
  }
  &.outlinedPrimary {
    color: $color-primary !important;
    background-color: $color-white;
    border: 2px solid $color-primary;
  }
  &.outlinedSecondary {
    color: $color-secondary !important;
    background-color: $color-white;
    border: 2px solid $color-secondary;
  }
  &.accent {
    color: $color-white !important;
    background-color: $color-accent;
    border: 2px solid $color-accent;
  }
  &.borderless {
    color: $color-grey-dark;
    &:hover {
      background-color: #f7f7f7;
    }
  }
}
