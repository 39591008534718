@import "../../../styles/vars";

.contentWrapper {
  max-width: 300px;
}

.title {
  font-size: 22px;
  font-family: $font-oswald;
  font-weight: $font-oswald-weight-bold;
  margin-bottom: 5px;
  color: $color-secondary;
}

.text {
  margin-bottom: 10px;
  font-size: 14px;
}

.submitButton {
  width: 100%;
}
