@import "../../../styles/vars.scss";

.footer {
  margin-top: 80px;
  background: linear-gradient(90deg, $color-primary 0%, $color-secondary 100%);
  color: $color-white;
  list-style: none;
}

.footerNav {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: $desktop-width) {
    flex-direction: column;
    align-items: center;
  }
}

.leftWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
}
.logoWrapper {
  align-items: center;
  margin-bottom: 10px;
}
.logo {
  width: 200px;
  height: auto;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.claim {
  font-size: 12px;
  text-align: center;
}
.legalRightsWrapper {
  text-align: center;
  font-size: 14px;
}

.rightWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 50px;
  column-gap: 50px;
  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  &:last-of-type {
    margin-right: 0;
  }
  a {
    text-decoration: none;
  }
}
.sectionTitle {
  font-size: 18px;
  font-family: $font-oswald;
  font-weight: $font-oswald-weight-bold;
  margin-bottom: 10px;
}
.sectionItem {
  margin-bottom: 5px;
}
.linkWithImage {
  display: flex;
  align-items: center;
  width: fit-content;
  span {
    margin-left: 10px;
  }
}
.emailWrapper {
  display: flex;
  align-items: center;
}
.email {
  text-decoration: underline;
}
