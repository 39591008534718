@import '../../../styles/vars.scss';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: relative;
  border-radius: 100%;
  background: linear-gradient($color-primary, $color-secondary);
  animation: animate 1s linear infinite;
  &:before {
    position: absolute;
    content: "";
    background: $color-white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    border-radius: 100%;
  }
}
