@import "../../../../styles/vars.scss";

.menu {
  display: flex;
  @media (max-width: $desktop-width) {
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: 100vh;
    top: 0;
    left: -300px;
    width: 250px;
    background: $color-white;
    padding: 30px 20px;
    box-shadow: 0px 2px 10px 0px rgba($color-primary-rgb, 0.6);
    z-index: 9;
    transition: left 0.3s ease-out;
    &.menuOpen {
      left: 0;
    }
  }
}

.list {
  display: flex;
  align-items: center;
  @media (max-width: $desktop-width) {
    margin-top: 20px;
    flex-direction: column;
    align-items: start;
  }
}

.item {
  margin-left: 30px;
  &:first-of-type {
    margin-left: 0;
  }
  @media (max-width: $desktop-width) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.link {
  color: $color-primary-dark;
  text-decoration: none;
  display: block;
  font-weight: bold;
  position: relative;
}

.linkWithoutBg {
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    background: $color-primary;
    transition: height 0.1s ease-out;
  }
  &:hover {
    &:after {
      height: 2px;
      transition: height 0.1s ease-out;
    }
  }
}

.contact {
  color: $color-white;
  background-color: $color-accent;
  padding: 8px 15px;
  font-family: $font-source-sans-pro;
  font-weight: bold;
  border-radius: 5px;
}

.logo {
  display: none;
  width: 160px;
  height: auto;
  @media (max-width: $desktop-width) {
    display: block;
  }
}

.languageButtons {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: $desktop-width) {
    align-items: flex-start;
    margin-left: 0;
  }
}

.language {
  cursor: pointer;
  font-size: 12px;
  @media (max-width: $desktop-width) {
    font-size: 16px;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.selectedLanguage {
  color: $color-primary;
}
